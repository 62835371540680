/**
 * カード登録・編集の遷移コントロール用middleware
 */

import { useCardState } from '@/composables/store/useCardStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const { editingCard } = useCardState();

  // 編集中のカードがない場合はカード一覧画面に遷移
  if (!editingCard.value) {
    return navigateTo({
      path: '/card',
    });
  }
});
